.question {
    text-align: left;
    box-shadow: 1px 1px 4px 0px #23caef;
    min-height: 30vh;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 30px;
}

.question h2 {
    display: flex;
    align-items: center;
    text-align: center;
}

.question h2 p {
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
}

.question__button {
    border-radius: 25px;
    color: #02c2e8;
    background-color: rgb(225, 250, 254);
    border: none;
    min-width: 100px;
    padding: 10px 15px;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px #02c2e8;

}

.btn-submit {
    background-color: rgb(253, 240, 240);
    color: #ff5252;
    box-shadow: 1px 1px 4px 0px #ff5252;

}



.question__action {
    margin-top: 2vh;
    display: flex;
    justify-content: space-between;
}

.question__item {
    box-shadow: 1px 1px 4px 0px #888787cb;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    cursor: pointer;
}

.question__item:hover {
    box-shadow: 1px 1px 4px 0px #f8131350;
}

.bbbb {
    padding: 0 7px;
    padding-bottom: 10px;

}

.action {
    box-shadow: 1px 1px 4px 0px #02c2e8;
    border: 2px solid #02c2e8;
}

.action_success {
    background-color: #85de77;
    border: 2px solid #85de77;
    /* background: linear-gradient(90deg, #b5ff60, #0acf1ab4); */
    color: #ffffff;
}

.action_fail {
    background-color: #FF756d;
    border: 2px solid #FF756d;
    color: #ffffff;

}

.correctAnswer {
    display: flex;
    align-items: center;
}

.correctAnswer p {
    padding-left: 10px;
    color: #4CAF50;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.checkQuantityCorrect {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 0 30px;
    padding-bottom: 30px;
}

.faCircleCheck {
    color: #4CAF50;
}

.faCircleXmark {
    color: #FF5252;
}

.checkQuantityCorrect div {
    font-size: 16px;
    font-weight: 600;
}

.lStart {
    min-height: 250px;
    position: relative;
    padding-top: 97px;
    padding-bottom: 43px;
    background-color: #fff;
    border-radius: 15px;
}

.lStart__bg {
    position: absolute;
    width: 100%;
    height: 250px;
    top: 0;
    left: 0;
    background: radial-gradient(74.21% 52.78% at 30.22% 82.87%, #56bcff 0, #65e2f5 100%);
    border-radius: 15px 15px 100px 100px;
    z-index: 0;
}

.lStart__data {
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-radius: 20px;
    max-width: 65%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
    padding-bottom: 48px;
    margin: 0 auto;
}

.lStart__progress {
    position: relative;
}

.lStart-progress-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 170px;
    background: rgba(139, 235, 253, .2);
    border-radius: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-85px);
}

.box-layer-2 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 149px;
    height: 149px;
    background: rgba(149, 224, 250, .2);
    border-radius: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-74.5px);
}

.box-layer-3 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background: #ffffff;
    border-radius: 100%;
    box-shadow: 0 2px 20px 0 rgba(11, 153, 234, .4);
    left: 50%;
    transform: translateX(-50%) translateY(-61px);
}

.box-layer-4 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 105px;
    background: #ffffff;
    border-radius: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-55px);
}

.box-layer-5 {
    position: absolute;
    display: flex;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    color: #7185ff;
    left: 50%;
    transform: translateX(-50%) translateY(-61px);
}

.main-statistics {
    padding-top: 112px;
}

.main-statistics-questions-start {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
}

.main-stats-data-questions-stat-item h3 {
    margin: 0;
}

.main-stats-data-questions-stat-item h2 {
    margin: 0;
}

.item-total {
    color: rgb(132, 155, 182);
}

.item-correct {
    color: rgb(130, 188, 36);
}

.item-incorrect {
    color: rgb(255, 84, 84);
}

.main-statistics-questions-button {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
}

.main-statistics-questions-button-btn {
    margin: 0px auto;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: none;
    display: flex;
    text-align: center;
    width: 170px;
    height: 45px;
    border-radius: 50px;
    box-shadow: rgb(211, 217, 229) 0px 2px 6px 0px;
    font-size: 16px;
    font-weight: 700;
    color: rgb(255, 255, 255) !important;
    background: linear-gradient(rgb(44, 225, 193) 0%, rgb(35, 202, 239) 100%) !important;
    /* background: linear-gradient(0deg, rgb(248, 105, 182) 0%, rgb(255, 148, 140) 100%) !important; */
}

.btn__review {
    background: linear-gradient(0deg, rgb(248, 105, 182) 0%, rgb(255, 148, 140) 100%) !important;
}


.question-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
}

.question-item-img {
    height: 230px;
    width: 100%;
    border-radius: 10px;
}


.Lquestion__action {
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.Lquestion__action::before {
    content: '';
    background-color: #56bcff;
    position: absolute;
    width: 100%;
    height: 1px;
    top: -5px;
}

.Transcript p {
    margin: 0;
}

.Transcript span {
    margin: 0;
    font-weight: 600;
}

.Lquestion__btn {
    border-radius: 25px;
    color: #02c2e8;
    background-color: rgb(225, 250, 254);
    border: none;
    min-width: 100px;
    padding: 10px 15px;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px #02c2e8;

}

.Lquestion__Transcript-active {
    align-items: flex-end;
    display: inline-flex;
    justify-content: flex-end;
}

.history__heading {
    text-align: left;
}


.history__list {
    overflow: hidden;
    height: 25vh;
    padding-left: 0;
    overflow-y: auto;
    padding: 2px;
    flex-flow: inherit;
}


#history::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #F5F5F5;
    border-radius: 10px;
    display: none;
}

#history::-webkit-scrollbar-thumb {
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
    border-radius: 10px;
}

#history::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(32, 181, 226, 0.438);
    background-color: #24e4f0;
}


.history__item {
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding: 5px 10px;
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgb(235, 235, 235);
    cursor: pointer;
}


.history__item:hover {
    box-shadow: 1px 1px 4px 0px #02c2e8;

}

.Chill__question {
    overflow: hidden;
    height: 300px;
    padding-left: 0;
    overflow-y: auto;
    padding: 2px;
    width: 100%;
    flex-flow: inherit;
}

#chill::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #F5F5F5;
    border-radius: 10px;
}

#chill::-webkit-scrollbar-thumb {
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
    border-radius: 10px;
}

#chill::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(32, 181, 226, 0.438);
    background-color: #24e4f0;
}


