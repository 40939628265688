.product {
    margin-left: 10px;
    margin-right: 10px;
}

.product__heading {
    display: flex;
    justify-content: space-between;
}

.product__deading {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;

}

.product__create{
    margin-top: 15px;
}

.product__create-btn {
    background-color: #43d61e;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 1px 3px 5px 1px rgba(17, 18, 19, 0.35);
}

.product__create-btn:hover {
    background-color: #3af30b !important;
   
}

.product__deading-icon {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    align-items: center;
    padding: 15px;
    font-size: 16px;
    color: #43d61e;
    box-shadow: 1px 3px 5px 1px rgba(17, 18, 19, 0.35);
    margin-right: 10px;
}