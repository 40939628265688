body {
  margin: 0;
  
}
:root {
  font-family: "Exo 2", "Lato", "Helvetica Neue", sans-serif !important;
}

code {
}
