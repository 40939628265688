.header-page {
    background: #ffffff;
    color: #ffffff;
    font-size: 18px;
    height: 56px;
    border-bottom: 2px solid #dedede;
    position: relative;
    z-index: 10;
    max-width: 100%;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px 2px rgb(210 210 210 / 80%);
    padding: 0px 16px;
  }
  .header-page__search{
    width: 100%;
      max-width: 250px;
  }
  .header-page__title{
    flex-shrink: 0;
  }
  .header-page__title h3.ant-typography {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .menu-item {
    width: 100%;
    height: 100%;
    padding: 2px 8px;
  }

  .menu-item .menu-item__btn {
    width: 100%;
    height: 100%;
  }

  .header-page .wrapper {
    flex-flow: nowrap;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
  .header-page .wrapper .ant-menu-horizontal {
    background: transparent;
    border: 0;
    color: #ffffff;
    padding: 0px 10px;
  }
  
  .btnBack{
    border-radius: 25px;
    color: #02c2e8;
    background-color: rgb(225, 250, 254);
    border: none;
    min-width: 100px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px #02c2e8;
  }