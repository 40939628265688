.login {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.login_body {

    position: absolute;
    /* background-image: url("../../img/img.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; */
    background-color: white;
    width: 800px;
    height: 600px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 6px 5px rgb(0 0 0 / 10%);
}

.login_left {
    overflow: hidden;
}

.login_img {
    margin-top: 50px;
    margin-left: 50px;
    border-radius: 20px;
    width: 300px;
    height: 450px;
    background-image: url("https://nv.edu.vn/wp-content/uploads/2020/08/english-course.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.login_rigth {}

.login_heading {
    margin-top: 30px;
    font-size: 60px;
    text-align: left;
    color: #2c2c2c;
}

.login_form {
    text-align: left;
}

.login_item {
    margin: 10px;
    margin-left: 0;
}


.login_text {
    display: block;
    font-size: 15px;
    margin: 10px;
    margin-left: 0;
    color: #09cfdd;
    font-weight: 500;
}
.login_icon {
    font-size: 20px;
    padding: 10px;
}

.login_input {
    border-radius: 50px;
    padding: 10px 50px 10px 15px;
    font-size: 14px;
    border-style: none;
    border: 1px solid #0a0a0a;
    outline: none;
    color: #656a6e;
    transition: .25s ease;
    border: 1px solid #e8ebee;
}

.login_input:focus {
    padding: 10px 100px 10px 35px;
    box-shadow: 4px 4px 5px 1px rgba(145, 146, 146, 0.35);

}

.login_button {
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    width: 100px;
    color: #edeef0;
    font-weight: 600;
    cursor: pointer;
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
}

.login_item-link {
    color: #0eb7fa;
    font-weight: 500;
}
.login_check-text {
    color: #656a6e;
    padding-left: 10px;
}

.login_register-text {
    color: #656a6e;
}