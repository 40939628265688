.pListen {
    z-index: 10;
    border-radius: 20px;
    box-shadow: 1px 1px 4px 0px #23caef;
    overflow: hidden;
    transition: linear 0.15s;

}

.pListen__title {
    color: #239aef;
    z-index: 1;
    text-align: left;
}

.pListen_img {
    height: 100%;
    min-height: 160.049px;
    width: 100%;
    background-image: url("https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGlzdGVufGVufDB8fDB8fHww&w=1000&q=80");
    margin-bottom: 22px;
}

.pListen__link {
    color: #000;
    display: block;
}

.pListen:hover {
    margin-top: -5px;
    box-shadow: 1px 4px 4px 0px #23caef;
    color: #23caef;
}

.pListen p {
    padding: 0 10px;
    text-align: left;
    width: 221px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 50px;
}

.pListen h2 {
    margin: 0;
    font-size: 15px;
    padding: 0 10px;
    text-align: left;
}

.pListen__heading {
    color: #239aef;
    z-index: 1;
}

.test {
    display: flex !important;
    overflow-x: auto;
    padding: 10px 0;
    flex-flow: inherit;
}


#pListen__list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #F5F5F5;
    border-radius: 10px;
}

#pListen__list::-webkit-scrollbar-thumb {
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
    border-radius: 10px;
}

#pListen__list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(32, 181, 226, 0.438);
    background-color: #24e4f0;
}

.pListen__title-icon {
    padding-right: 10px;
}