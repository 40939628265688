.vocabulary__item-icon {
    padding: 12px 15px;
    border-radius: 6px;
    color: #ff0909ad;
    box-shadow: 1px 1px 4px 0px #23caef;

}

.vocabulary {
    margin: 10px 10px;
    padding: 0 10px;
    border-radius: 6px;
    min-height: 55vh;
}

.vocabulary__heading {
    text-align: left;
}

.vocabulary__deading {
    display: flex;
    align-items: center;

}

.vocabulary__deading-text {
    padding-left: 10px;
}

.vocabulary__body-item {
    margin-bottom: 15px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #eeeeeead;
}

.vocabulary__body-item:hover {
    box-shadow: 1px 4px 4px 0px #f8131350;
    color: #ff0909ad;
    cursor: pointer;
}

.vocabulary__body-item h3 {
    padding: 12px 15px;
    margin: 0;
    align-items: center;
    display: block;
}

.vocabulary__body-icon1 {
    margin-right: 20px;
    border-radius: 6px;
    color: #ff0909ad;
}

.vocabulary__body-icon {
    padding-right: 20px;
}