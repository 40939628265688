.header{
    text-align: left;
    background-color: white ;
    margin-left: 10px;
    margin-right: 10px;
}

.header__left {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    padding-left: 40px;
    z-index: 1;
    background-color: white ;
    background: linear-gradient(90deg,#ffbf96,#fe7096);
}

.header__chart {
    border-radius: 5px;
    height: 180px !important;
    z-index: 5;
    width: 300px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    right: -80px;
    top: -30px;
}

.header__icon {
    border-radius: 50%;
    height: 180px;
    z-index: 1;
    opacity: 0.3;
    width: 180px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    right: -80px;
    top: -30px;
}

.header__icon1 {
    border-radius: 50%;
    height: 200px;
    z-index: 0;
    width: 200px;
    opacity: 0.3;
    position: absolute;
    background-color: rgb(255, 255, 255);
    right: -30px;
    bottom: -80px;
}

.header__center {
    cursor: pointer;
    border-radius: 7px;
    padding-left: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: white ;
    background: linear-gradient(90deg,#84d9d2,#07cdae);
}

.header__rigth {
    cursor: pointer;
    border-radius: 7px;
    padding-left: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: white ;
    background: linear-gradient(90deg, #b5ff60, #0acf1b);
}

.header__heading {
    margin-top: 0;
    padding-top: 30px;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
}

.header__price {
    color: #ffffff;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 50px;
}

.header__desc {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}
