.ChatBox-container {
    background: rgba(255, 255, 255, 0.64);
    ;
    border-radius: 1rem;
    display: grid;
    grid-template-rows: 11vh 64vh 13vh;
}

.chat-header {
    padding: 1rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(88, 87, 87, 0.1);
    z-index: 10;
}

.follower {
    text-align: left;
}

.chat-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem;
    overflow-y: auto;
}

#style-1::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
    border-radius: 10px;
}

#style-1::-webkit-scrollbar-thumb {
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
    border-radius: 10px;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(32, 181, 226, 0.438);
    background-color: #F5F5F5;
}


.message {

    background: linear-gradient(98.63deg, #ecaf59 0%, #ff5729 100%);
    color: white;
    padding: 0.7rem;
    border-radius: 1rem 1rem 1rem 0;
    max-width: 28rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.message>span:nth-child(2) {
    font-size: 0.7rem;
    color: var(--textColor);
    align-self: end;
}

.own {
    align-self: flex-end;
    border-radius: 1rem 1rem 0 1rem;
    background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
}



.chat-sender {
    background: white;
    display: flex;
    justify-content: space-between;
    height: 6.1rem;
    align-items: center;
    gap: 1rem;
    padding: 0.8rem;
    align-self: end;
    border-top: 1px solid rgb(240, 239, 239);
    border-left: 1px solid rgb(240, 239, 239);
}

.chat-sender>input {
    height: 70%;
    background-color: rgb(236, 236, 236);
    border-radius: 0.5rem;
    border: none;
    outline: none;
    flex: 1;
    font-size: 14px;
    padding: 0px 15px 0px 15px;
}

.chat-sender>div:nth-of-type(1) {
    background: rgb(233, 233, 233);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
}

.chat-sender>div {
    height: 70%;
    padding: 0px 15px 0px 15px;
}

.chatbox-empty-message {
    font-size: 18px;
    margin-top: 40vh;
    font-weight: 500;
    color: #a1a1a1;
}